<template>
  <div class="v-dashboard">
    <Topbar topbar-component="TopbarMain" />

    <Modal v-if="isModalOpen" />

    <div class="v-dashboard__wrapper">
      <div class="v-dashboard__welcome">
        <img
          class="v-dashboard__welcome-img"
          alt="Robot Sven"
          src="themes/synopsis/assets/Robot.png"
        />

        <div class="v-dashboard__welcome-text">
          <h1 class="v-dashboard__welcome-title">
            {{ $t('WELCOME_HI') }}, {{ user.name.givenName }}
          </h1>

          <p class="v-dashboard__welcome-intro">{{ $t('WELCOME_INTRO') }}</p>
        </div>
      </div>

      <div class="v-dashboard__arenas">
        <h2>{{ $t('DASHBOARD_ARENA_HEADER_TEXT') }}</h2>

        <div class="v-dashboard__arenas-wrapper" v-if="asyncDataReady">
          <Card
            v-for="(arena, i) in arenas"
            :class="[
              'v-dashboard__arena',
              { 'v-dashboard__arena--dark': i === 3 },
              {
                'v-dashboard__arena--disabled': isDisabled(i),
              },
            ]"
            :key="arena.id"
            item-type="arena"
            :item="arena"
            :title="arena.name"
            :description="arena.description"
            :image="arena.cover"
            :link="`/arena/${arena.slug}`"
            :progress="activitiesProgress[arena.id]"
            :items-count="countMap[arena.id]"
            :slug="arena.slug"
            :disabled="isDisabled(i)"
          />
        </div>
      </div>
    </div>
  </div>

  <Curtain />
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import {
  useTenant,
  useAuth,
  useStructures,
  useContents,
  useLocalizations,
} from '@/core'
import { useHead } from '@vueuse/head'
import { getActivityProgress } from '@/services/progress'
import { getStructuresChildren } from '@/core/services/structures'
import useModal from '@/composables/useModal'
import Topbar from '@/components/Topbar'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import useCurtain from '@/composables/useCurtain'
import Curtain from '@/components/Curtain'
import useTranslations from '@/composables/useTranslations'

import { getSetting } from '../services/userSettings'
import { useI18n } from 'vue-i18n'

export default {
  name: 'Dashboard',

  components: {
    Card,
    Topbar,
    Modal,
    Curtain,
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      vm.checkPreviousRoute(from) //Provided fetchData is accessible in the component
    })
  },

  setup() {
    const { tenant } = useTenant()
    const {
      fetchStructuresNode,
      getStructuresBySlugPath,
      fetchStructuresChildren,
      getStructuresChildrens,
    } = useStructures()
    const { getObjectById } = useContents()
    const { setModal, isModalOpen, openModal } = useModal()
    const { setCurtain, curtainType } = useCurtain()
    const videoObject = ref({})
    const arenas = ref([])
    const arenasSlug = computed(() => `${tenant.value.slug}/arenas`)
    const { user } = useAuth()
    const activitiesProgress = ref({})
    const countMap = ref({})
    const asyncDataReady = ref(false)
    const fromAppRoute = ref(false)
    const { locale } = useI18n()
    const { gqlContentQuery, isFallbackLanguage } = useTranslations()
    const { fetchStructuresLocalization } = useLocalizations()

    useHead({
      title: `Dashboard - ${tenant.value.name}`,
    })

    const checkPreviousRoute = from => {
      fromAppRoute.value = from.path !== '/'
    }

    // i>1 because the last two arenas are intentionally blocked
    const isDisabled = i => {
      return i !== 0 && (!arenas.value[i - 1].isDone || i > 1)
    }

    onMounted(async () => {
      //get intro video
      await fetchStructuresNode(tenant.value.slug)
      const courseId = getStructuresBySlugPath(tenant.value.slug).id
      await fetchStructuresChildren(tenant.value.slug, {
        limit: 1,
        filter: '{"type": "OBJECT"}',
      })
      const courseObject = getStructuresChildrens(courseId, 'OBJECT')
      videoObject.value = courseObject[0]
      const userLang = await getSetting('lang')
      await gqlContentQuery(videoObject.value.contentId, userLang?.value)
      videoObject.value.contentData = getObjectById(videoObject.value.contentId)
      if (!userLang) {
        setCurtain('CurtainLanguageSwitcher', 'langSwitcherAutoInvoked', {
          allowDissmiss: false,
        })
      } else {
        locale.value = userLang.value
        if (!fromAppRoute.value) {
          showVideoModal()
        }
      }

      //get arenas
      await fetchStructuresNode(arenasSlug.value)
      await fetchStructuresChildren(arenasSlug.value, {
        limit: 10,
        filter: '{"type": "DIRECTORY"}',
      })

      const arr = getStructuresChildrens(
        getStructuresBySlugPath(arenasSlug.value).id,
        'DIRECTORY',
        locale.value,
      )
      //TODO: if nn
      try {
        if (!isFallbackLanguage.value) {
          await Promise.all(
            arr.map(async ar => {
              await fetchStructuresLocalization(ar.id, locale.value)
            }),
          )
        }
      } catch {
        //
      }

      const arenasCollector = getStructuresBySlugPath(
        arenasSlug.value,
        true,
        'DIRECTORY',
        locale.value,
      )
      arenas.value = arenasCollector.childrens

      await Promise.all(
        arenas.value.map(async item => {
          const ap = await getActivityProgress(item.id)
          countMap.value[item.id] = (
            await getStructuresChildren(tenant.value.namespace, item.id, {
              limit: 1,
              filter: '{"type": "DIRECTORY"}',
            })
          ).pagination.total
          activitiesProgress.value[item.id] = ap

          const activitiesProgressArr = ap.itemsCompleted

          const isArray = Array.isArray(activitiesProgressArr)

          if (isArray) {
            if (activitiesProgressArr.length === countMap.value[item.id]) {
              item.isDone = true
            }
          }
        }),
      )

      asyncDataReady.value = true
    })

    const showVideoModal = () => {
      setModal('video', videoObject.value)
      openModal()
    }

    watch(curtainType, (current, prev) => {
      if (prev === 'langSwitcherAutoInvoked' && !current) {
        showVideoModal()
      }
    })

    return {
      arenas,
      activitiesProgress,
      countMap,
      user,
      videoObject,
      isModalOpen,
      checkPreviousRoute,
      asyncDataReady,
      isDisabled,
    }
  },
}
</script>
