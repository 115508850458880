<template>
  <div v-if="isModalOpen" class="c-modal" @click.self="closeModal">
    <div class="c-modal__wrapper">
      <div class="c-modal__inner">
        <div class="c-modal__top">
          <div class="c-modal__top-wrapper">
            <TheHeading class="c-modal__top-heading">
              {{ modalContent.name }}
            </TheHeading>
          </div>
        </div>
        <div class="c-modal__content">
          <div class="c-modal__content-wrapper">
            <!-- <Video :data="modalContent" /> -->
            <ContentRenderer
              v-if="modalContent"
              :content-items="[modalContent]"
              @contentCompleted="onContentCompleted"
            />
          </div>
        </div>
        <div class="c-modal__footer">
          <TheButton
            v-if="allowModalClose"
            type="primary"
            rounded
            class="c-modal__footer-close"
            @click="closeModal"
          >
            {{$t('SKIP_INTRO')}}
          </TheButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import useModal from '@/composables/useModal'

import TheHeading from '@/components/TheHeading'
import TheButton from '@/components/TheButton'
import ContentRenderer from '@/components/ContentRenderer'

export default {
  components: {
    TheHeading,
    TheButton,
    ContentRenderer,
  },

  setup() {
    const {
      isModalOpen,
      allowModalClose,
      closeModal,
      modalContent,
      setModalComplete,
    } = useModal()
    const contentItems = ref([])

    const onContentCompleted = async () => {
      await setModalComplete()
      closeModal()
    }

    return {
      isModalOpen,
      contentItems,
      modalContent,
      allowModalClose,
      closeModal,
      onContentCompleted,
    }
  },
}
</script>
