<template>
  <div class="c-card" :class="[`c-card--${slug}`, `c-card--${status}`]">
    <div class="c-card__wrapper">
      <div class="c-card__content">
        <div
          class="c-card__progress-bar c-card__progress-bar--top"
          :title="`${progressBar}%`"
        >
          <div
            class="c-card__progress-progress"
            :style="{ width: `${progressBar}%` }"
          ></div>
        </div>
        <div v-if="image" class="c-card__image">
          <img class="c-card__image-image" :src="cover?.url" :alt="title" />
        </div>
        <div class="c-card__text">
          <h3 v-if="title" class="c-card__title">
            {{ title }}
          </h3>
        </div>
        <div class="c-card__progress">
          <span
            >{{ progressCount }}/{{ itemsCount }}
            {{ $t('CARD_ITEMS_FINISHED') }}</span
          >
          <div class="c-card__progress-bar">
            <div
              class="c-card__progress-progress"
              :style="{ width: `${progressBar}%` }"
            ></div>
          </div>
        </div>
        <div class="c-card__actions">
          <TheButton
            v-if="disabled"
            :disabled="disabled"
            type="primary"
            class="c-card__btn c-card__btn--disabled"
            icon="lock"
          >
            {{ $t('CARD_LOCKED') }}
          </TheButton>
          <TheButton
            v-else
            class="c-card__btn"
            :type="btn.type"
            @click="btn.action"
          >
            {{ btn.text }}
          </TheButton>
          <router-link
            v-if="status === 'done' && itemType !== 'arena'"
            :to="link"
            class="c-card__link"
          >
            {{ $t('CARD_DONE_LINK') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useAssets, utils, useTenant } from '@/core'
import useCurtain from '@/composables/useCurtain'
import TheButton from '@/components/TheButton'

export default defineComponent({
  name: 'Card',

  components: {
    TheButton,
  },

  props: {
    title: String,
    image: String,
    slug: String,
    link: String,
    disabled: Boolean,
    progress: Object,
    item: Object,
    itemsCount: Number,
    itemType: String,
  },

  setup(props) {
    const { setCurtain } = useCurtain()
    const { t } = useI18n()
    const router = useRouter()
    const { tenant } = useTenant()
    const cover = ref(null)
    const { fetchAsset, getAssetById } = useAssets()
    const { addCloudinaryUrlParams } = utils
    const hiddenPath = computed(() => tenant.value.hiddenCover)
    const cardProgress = computed(() => props.progress || null)
    const progressCount = computed(
      () =>
        (cardProgress.value && cardProgress.value.itemsCompleted?.length) || 0,
    )
    const status = computed(() => {
      let s

      if (props.disabled) {
        s = 'disabled'
      } else if (
        !cardProgress.value ||
        (cardProgress.value.reset && !cardProgress.value.itemsCompleted.length)
      ) {
        s = 'start'
      } else if (cardProgress.value.done && !cardProgress.value.reset) {
        s = 'done'
      } else if (props.itemType === 'arena' && props.item.isDone) {
        s = 'arenaDone'
      } else {
        s = 'inProgress'
      }

      return s
    })
    const btn = computed(() => {
      return mapBtn[status.value]
    })
    const mapBtn = {
      start: {
        text: t('CARD_START'),
        type: 'primary',
        action: () => {
          router.push(props.link)
        },
      },
      done: {
        text: t('CARD_DONE'),
        type: 'success',
        action: () => {
          setCurtain('CurtainAction', 'ResetActivity', null, props.item)
        },
      },
      arenaDone: {
        text: t('CARD_DONE'),
        type: 'success',
        action: () => {
          router.push(props.link)
        },
      },
      inProgress: {
        text: t('CARD_IN_PROGRESS'),
        type: 'info',
        action: () => {
          router.push(props.link)
        },
      },
    }
    const progressBar = computed(
      () =>
        (props.itemsCount &&
          cardProgress.value &&
          (cardProgress.value.itemsCompleted?.length * 100) /
            props.itemsCount) ||
        0,
    )

    onMounted(async () => {
      if (props.image) {
        if (props.image === 'hidden') {
          cover.value = { url: hiddenPath.value.url }
        } else {
          await fetchAsset(props.image)
          cover.value = getAssetById(props.image)
          cover.value.url = addCloudinaryUrlParams(cover.value.url, 'w_100')
        }
      }
    })

    return {
      btn,
      status,
      cover,
      cardProgress,
      progressBar,
      progressCount,
      hiddenPath,
    }
  },
})
</script>
